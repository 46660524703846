body {
  margin: 0;
  background: linear-gradient(to bottom, #000030 0%, #000030 35%, #000030 65%, #000030 100%);
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Work Sans', sans-serif;
}